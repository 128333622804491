<template>
  <div class="tableWrap">
    <div
      class="table-top-bar"
      v-if="!selectedRowScopes.length"
    >
      <div class="leftBar">
        <el-dropdown
          split-button
          type="primary"
          size="small"
          @command="handleCommand"
          @click="handleCommandClick"
          :disabled="true"
        >
          {{ adType }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="创建标准广告">创建标准广告</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="midFilter">
        <div v-if="!filterShow">
          <el-dropdown
            trigger="click"
            @command="handleFilterCommand"
            placement="bottom"
          >
            <el-button type="text">
              <el-image
                :src="filterIcon"
                style="width: 16px; height: 16px; margin-right: 5px; vertical-align: middle"
              ></el-image>
              <span style="color: #999">筛选</span></el-button
            >
            <el-dropdown-menu
              slot="dropdown"
              style="width: 460px"
              ref="filterDrop"
            >
              <div class="cusFilter">
                <div
                  class="cusFilterList"
                  v-if="initData"
                >
                  <div
                    class="cusFilterItem"
                    v-for="(filterObj, index) in cusFilterList"
                    :key="filterObj.field"
                  >
                    <el-row
                      align="middle"
                      :gutter="5"
                    >
                      <el-col :span="6">
                        <el-select
                          size="mini"
                          v-model="filterObj.field"
                          popper-class="dropselect"
                          @change="fieldChange(cusFilterList, filterObj, index)"
                        >
                          <el-option
                            v-for="filterItem in initData.FilterFields"
                            :key="filterItem.keyName"
                            :label="filterItem.name"
                            :value="filterItem.keyName"
                            :disabled="
                              (cusFilterList.filter((v) => v.field == 'status').length > 0 &&
                                filterItem.keyName == 'status') ||
                              (cusFilterList.filter((v) => v.field == 'objective').length > 0 &&
                                filterItem.keyName == 'objective') ||
                              (cusFilterList.filter((v) => v.field == 'ad_label').length > 0 &&
                                filterItem.keyName == 'ad_label')
                            "
                          >
                            <span @click="showFilterDrop">{{ filterItem.name }}</span>
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="5">
                        <el-select
                          size="mini"
                          v-model="filterObj.operator"
                          v-if="filterObj.field"
                          popper-class="dropselect"
                          :disabled="
                            initData.FilterFields.filter((v) => v.keyName == filterObj.field)[0].filtering.length == 1
                          "
                        >
                          <el-option
                            v-for="operators in initData.FilterFields.filter((v) => v.keyName == filterObj.field)[0]
                              .filtering"
                            :key="operators.desc"
                            :label="operators.desc"
                            :value="operators.key"
                          >
                            <span @click="showFilterDrop">{{ operators.desc }}</span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-model="filterObj.operator"
                          disabled
                          popper-class="dropselect"
                          v-else
                        >
                        </el-select>
                      </el-col>
                      <el-col :span="11">
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'objective'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in initData.CampaignObjective"
                            :key="filterItem.key"
                            :label="filterItem.desc"
                            :value="filterItem.key"
                          >
                            <span @click="showFilterDrop">{{ filterItem.desc }}</span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'status'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in initData.FilteringStatus"
                            :key="filterItem.key"
                            :label="filterItem.desc"
                            :value="filterItem.key"
                          >
                            <span @click="showFilterDrop">{{ filterItem.desc }}</span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'ad_label'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in labelList"
                            :key="filterItem.labelId"
                            :label="filterItem.name"
                            :value="filterItem.labelId"
                          >
                            <span @click="showFilterDrop">{{ filterItem.name }}</span>
                          </el-option>
                        </el-select>
                        <el-input
                          size="mini"
                          v-if="
                            filterObj.field != 'status' &&
                            filterObj.field != 'objective' &&
                            filterObj.field != 'ad_label'
                          "
                          v-model="filterObj.value"
                        ></el-input>
                      </el-col>
                      <el-col :span="2">
                        <i
                          class="el-icon-close"
                          v-if="cusFilterList.length > 1"
                          @click="deleFilterObj(index)"
                          style="margin-top: 5px"
                        ></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="btnGroup">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="saveFilterSearch"
                    >确定</el-button
                  >
                  <el-button
                    size="mini"
                    @click="addFilter"
                    >添加</el-button
                  >
                </div>
              </div>
              <el-dropdown-item
                disabled
                divided
                style="font-size: 12px"
                >你的筛选组合</el-dropdown-item
              >
              <el-dropdown-item
                v-for="filter in filterList"
                :key="filter.name"
                :command="filter"
                :class="[filterId == filter.id ? 'active' : '']"
              >
                <div class="columnName">
                  <span>{{ filter.name }}</span>
                  <div>
                    <p>
                      <i
                        class="el-icon-delete"
                        @click.stop="deleFilterGroup(filter.id)"
                      ></i>
                    </p>
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="filterWrap"
          v-if="filterShow"
        >
          <el-image
            :src="filterIcon"
            style="width: 14px; height: 14px"
          ></el-image>
          <div class="paramsFilters">
            <el-tag
              :key="i + 'tag'"
              v-for="(tags, i) in params.filtering"
              closable
              :disable-transitions="false"
              @close="deleFilterTag(tags, i)"
              size="mini"
              effect="dark"
              style="border-radius: 10px; font-size: 12px"
            >
              <div>
                <el-dropdown
                  trigger="click"
                  placement="bottom"
                >
                  <span style="color: #fff">{{
                    initData.FilterFields.filter((v) => v.keyName == tags.field)[0] | showName
                  }}</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-radio-group
                      v-model="tags.field"
                      @change="fieldChange(params.filtering, tags, i)"
                    >
                      <p
                        v-for="filterItem in initData.FilterFields"
                        :key="filterItem.keyName"
                        style="padding: 10px"
                      >
                        <el-radio
                          :label="filterItem.keyName"
                          :disabled="
                            (params.filtering.filter((v) => v.field == 'status').length > 0 &&
                              filterItem.keyName == 'status') ||
                            (params.filtering.filter((v) => v.field == 'objective').length > 0 &&
                              filterItem.keyName == 'objective') ||
                            (params.filtering.filter((v) => v.field == 'ad_label').length > 0 &&
                              filterItem.keyName == 'ad_label')
                          "
                        >
                          {{ filterItem.name }}
                        </el-radio>
                      </p>
                    </el-radio-group>
                  </el-dropdown-menu>
                </el-dropdown>
                <span style="margin: 0 5px">
                  <span v-if="tags.field == 'status' || tags.field == 'objective' || tags.field == 'ad_label'">{{
                    operatorName(initData.FilterFields.filter((v) => v.keyName == tags.field)[0], tags) || '包含'
                  }}</span>
                  <el-dropdown
                    trigger="click"
                    placement="bottom"
                    v-else
                  >
                    <span style="color: #fff">{{
                      operatorName(initData.FilterFields.filter((v) => v.keyName == tags.field)[0], tags) || '请选择'
                    }}</span>
                    <el-dropdown-menu slot="dropdown">
                      <el-radio-group v-model="tags.operator">
                        <p
                          v-for="filterItem in showFiltering(params.filtering[i])"
                          :key="filterItem.key"
                          style="padding: 10px"
                        >
                          <el-radio :label="filterItem.key">{{ filterItem.desc }}</el-radio>
                        </p>
                      </el-radio-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
                <span>
                  <el-dropdown
                    trigger="click"
                    placement="bottom"
                    v-if="tags.field == 'status' || tags.field == 'objective' || tags.field == 'ad_label'"
                  >
                    <span style="color: #fff">{{ valueName(tags).join(',') || '请选择' }}</span>
                    <el-dropdown-menu
                      slot="dropdown"
                      style="max-height: 400px; overflow-y: auto"
                    >
                      <el-checkbox-group
                        v-model="tags.value1"
                        @change="valueChange"
                      >
                        <p
                          v-for="(checkbox, index) in tags.field == 'ad_label'
                            ? labelList
                            : initData[tags.field == 'status' ? 'FilteringStatus' : 'CampaignObjective']"
                          :key="index"
                          style="padding: 10px"
                        >
                          <el-checkbox
                            :label="checkbox.labelId"
                            v-if="tags.field == 'ad_label'"
                            >{{ checkbox.name }}</el-checkbox
                          >
                          <el-checkbox
                            :label="checkbox.key"
                            v-else
                            >{{ checkbox.desc }}</el-checkbox
                          >
                        </p>
                      </el-checkbox-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <span v-else>
                    <el-input
                      v-model="tags.value"
                      class="tagInput"
                      @change="valueChange"
                    ></el-input>
                  </span>
                </span>
              </div>
            </el-tag>
            <el-tag
              size="mini"
              style="border-radius: 10px; font-size: 12px; border: 1px dashed #409eff; cursor: pointer"
              @click="addTag"
            >
              <i class="el-icon-plus"></i>
              <span>添加筛选项</span>
            </el-tag>
          </div>
          <div class="rightIcon">
            <i
              class="el-icon-finished"
              @click="saveFilterShow = true"
            ></i>
            <i
              class="el-icon-circle-close"
              @click="closeMyFilter"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="rightTools"
        :style="{ flex: searchInput ? '260px 0 0' : '100px 0 0' }"
      >
        <div class="searchInput">
          <el-input
            clearable
            placeholder="请输入内容"
            v-model="params.keyword"
            class="input-with-select"
            v-if="searchInput"
            @change="keywordChange"
          ></el-input>
          <el-button
            type="text"
            icon="el-icon-search"
            @click="params.keyword ? (searchInput = true) : (searchInput = !searchInput)"
          ></el-button>
        </div>
        <div class="tools">
          <div class="toolsItem">
            <el-tooltip
              class="item"
              effect="dark"
              content="定制列"
              placement="top"
            >
              <el-dropdown
                trigger="click"
                @command="handleColumnCommand"
                placement="bottom"
              >
                <el-button
                  type="text"
                  class="el-icon-s-grid"
                ></el-button>
                <el-dropdown-menu
                  slot="dropdown"
                  style="width: 300px"
                >
                  <el-dropdown-item command="定制列组合">定制列组合</el-dropdown-item>
                  <el-dropdown-item
                    disabled
                    divided
                    style="font-size: 12px"
                    >你的列组合</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-for="column in myCols.myColumnsArr"
                    :key="column.id"
                    :command="column"
                    :class="[myCols.myColumsId == column.id ? 'active' : '']"
                  >
                    <div class="columnName">
                      <span>{{ column.name }}</span>
                      <div v-if="!column.idefault">
                        <p>
                          <i
                            class="el-icon-edit"
                            @click.stop="editColumns(column)"
                          ></i>
                          <i
                            class="el-icon-delete"
                            @click.stop="deleColumns(column)"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-tooltip>
          </div>
          <div class="downLoad">
            <down-load :downLoadParams="{ ...downLoadParams, ids: [] }"></down-load>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="toolsBar"
        v-if="selectedRowScopes.length"
      >
        <div class="toolsCon">
          <p>已选{{ selectedRowScopes.length }}项</p>
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'ACTIVE')"
              >启动</el-button
            >
          </div>

          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'PAUSED')"
              >暂停</el-button
            >
          </div>
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="handleEditCommand({ type: 'adsets' })"
              v-if="selectedRowScopes.filter((v) => v.level == 'adset').length"
              >编辑</el-button
            >
            <el-button
              type="primary"
              plain
              size="mini"
              @click="handleEditCommand({ type: 'editAds' })"
              v-if="selectedRowScopes.filter((v) => v.level == 'ad').length"
              >编辑</el-button
            >
          </div>
          <div class="operate-btn">
            <!-- <span><i class="el-icon-edit-outline"></i></span> -->
            <el-dropdown
              trigger="click"
              @command="handleEditCommand"
              placement="bottom"
            >
              <el-button
                type="primary"
                plain
                size="mini"
                >批量操作</el-button
              >
              <el-dropdown-menu
                slot="dropdown"
                style="width: 260px"
              >
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告系列</span>
                    <div>已选{{ selectedRowScopes.filter((v) => v.level == 'campaign').length }}项</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'campaign' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'campaign').length"
                  >修改预算与竞价</el-dropdown-item
                >
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告组</span>
                    <div>已选{{ selectedRowScopes.filter((v) => v.level == 'adset').length }}项</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'adset' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                  >修改预算与竞价</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{ type: 'adsetPix' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                  >批量修改像素</el-dropdown-item
                >
                <!-- <el-dropdown-item
                  :command="{ type: 'adsets' }"
                  :disabled="
                    !selectedRowScopes.filter((v) => v.level == 'adset').length
                  "
                  >批量编辑广告组</el-dropdown-item
                > -->
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告</span>
                    <div>已选{{ selectedRowScopes.filter((v) => v.level == 'ad').length }}项</div>
                  </div>
                </el-dropdown-item>
                <!-- <el-dropdown-item  :command="{type:'ad'}" :disabled="!selectedRowScopes.filter(v=>v.level == 'ad').length">修改广告创意</el-dropdown-item> -->
                <el-dropdown-item
                  :command="{ type: 'adName' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                  >批量修改广告名称</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{ type: 'adUrl' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                  >批量添加广告网域</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="operate-btn">
            <!-- <span><i class="el-icon-delete"></i></span> -->
            <el-button
              type="primary"
              plain
              size="mini"
              @click="editCopyShow = true"
              >快速复制</el-button
            >
          </div>
          <div class="operate-btn">
            <!-- <span><i class="el-icon-delete"></i></span> -->
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'DELETED')"
              >删除</el-button
            >
          </div>
          <div
            class="operate-btn"
            v-show="level != 'ad'"
          >
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setTimeHandle"
              >定时开启</el-button
            >
          </div>
        </div>
        <div class="close">
          <i
            class="el-icon-close"
            @click="clearIds"
          ></i>
        </div>
      </div>
    </div>
    <!--  v-loading="loading" -->
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="id"
      :key="level"
      :lazy="lazy"
      :load="loadChildren"
      @selection-change="handleSelectionChange"
      border
      v-infinite-scroll="{ load: loadMore, distance: 0 }"
      :infinite-scroll-disabled="infiniteScrollDisabled"
      ref="table"
      @expand-change="expandChange"
      show-summary
      :summary-method="getSummaries"
      @sort-change="sortChange"
      stripe
      :cell-style="cellStyle"
      :header-cell-class-name="headerCellClassName"
      @row-click="rowClick"
      class="my-table"
    >
      <el-table-column
        type="selection"
        :selectable="canSelect"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="名称"
        width="360"
        sortable
      >
        <template slot-scope="scope">
          <ad-cell-name
            :value="scope"
            :disabled="disAbled"
            @scope-row-changed="refreshTableRow(scope)"
            @openChart="openChart"
            @setStatus="setStatus"
            @copy="copyRow"
            @handle-edit-command="handleEditCommand"
            :level="level"
            @open-next-level="openNextLevel"
            :multiAccount="true"
            @operationLog="operationLog"
          ></ad-cell-name>
        </template>
      </el-table-column>
      <el-table-column
        prop="effective_status"
        label="状态"
        width="100"
        sortable="custom"
      >
        <template #header>
          <div class="statusHeader">
            <span>状态</span>
            <el-popover
              placement="bottom"
              width="80"
              trigger="click"
              v-if="initData.FilteringStatus"
            >
              <el-checkbox-group
                v-model="checkList"
                class="toolsCheckTips"
                @change="selectStatus"
              >
                <el-checkbox
                  :label="checkbox.key"
                  v-for="checkbox in initData.FilteringStatus"
                  :key="checkbox.key"
                  >{{ checkbox.desc }}
                </el-checkbox>
              </el-checkbox-group>
              <i
                slot="reference"
                class="el-icon-discount"
              ></i>
            </el-popover>
          </div>
        </template>
        <template
          slot-scope="scope"
          v-if="scope.row.effective_status"
        >
          <div
            class="statusWarp"
            v-if="initData.AdStatus"
          >
            <span
              v-if="scope.row.effective_status != 'DELETED' && scope.row.effective_status != 'ARCHIVED'"
              :class="['pointer', scope.row.effective_status]"
            ></span>
            <span :class="['name', `name${scope.row.effective_status}`]">{{
              initData.AdStatus.filter((v) => v.key == scope.row.effective_status)[0].desc
            }}</span>
            <el-tooltip
              placement="bottom"
              width="600"
              trigger="hover"
              v-if="scope.row.status_hints && scope.row.status_hints.length"
              :enterable="true"
              effect="light"
              popper-class="rule-tip"
            >
              <div slot="content">
                <div
                  class="rule-score-wrap"
                  v-for="(item, index) in scope.row.status_hints"
                  :key="index + item.level"
                >
                  <div>
                    <i
                      class="el-icon-warning"
                      v-if="item.level == 'WARN'"
                    ></i
                    ><i
                      class="el-icon-error"
                      v-if="item.level == 'ERROR'"
                    ></i
                    ><span>{{ item.message }}</span>
                  </div>
                  <div
                    v-if="item.alertId"
                    style="margin-left: 5px"
                  >
                    <el-button
                      v-if="item.alertScore == null || item.alertScore == 1"
                      type="text"
                      size="mini"
                      :disabled="item.alertScore == 1"
                      @click="
                        reviewScore({
                          curTableRow: scope.row,
                          curTableIndex: scope.$index,
                          curRow: item,
                          curIndex: index,
                          flag: 1,
                        })
                      "
                      >准确</el-button
                    >

                    <el-button
                      v-if="item.alertScore == null || item.alertScore === 0"
                      type="text"
                      size="mini"
                      :disabled="item.alertScore === 0"
                      @click="
                        reviewScore({
                          curTableRow: scope.row,
                          curTableIndex: scope.$index,
                          curRow: item,
                          curIndex: index,
                          flag: 0,
                        })
                      "
                      >不准确</el-button
                    >
                  </div>
                </div>
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="effective_status"
        label="是否关联商品"
        width="120"
        v-if="level == 'campaign'"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.level == 'campaign'">
            <span v-if="scope.row.campaign_ref == 'NOT_REF'">
              <el-button
                size="mini"
                @click="relateSaleClick(scope)"
                >关联商品</el-button
              >
            </span>
            <span v-if="scope.row.campaign_ref == 'REF'">
              <span>已关联</span>
            </span>
            <span v-else>
              <span></span>
            </span>
          </p>
          <p v-else>--</p>
        </template>
      </el-table-column>
      <!-- 其它非固定字段 -->
      <!-- :width="item.keyName=='budget'?'200':item.width"
             :align="item.keyName=='object_actions'||item.keyName=='cost_per_action_type'?'right':'left'"-->
      <el-table-column
        :prop="item.keyName"
        :label="item.name"
        v-for="(item, index) in fields.filter(
          (v) => v.keyName != 'status' && v.keyName != 'id' && v.keyName != 'name' && v.keyName != 'campaign_ref',
        )"
        :key="item.keyName"
        :min-width="
          item.name.length >= 8
            ? '180'
            : item.name.length >= 5
            ? '110'
            : item.keyName == 'budget'
            ? '200'
            : item.keyName == 'local_order'
            ? '120'
            : item.keyName == 'local_event'
            ? '180'
            : item.keyName == 'open_schedule'
            ? '240'
            : item.keyName == 'ad_label'
            ? '240'
            : item.width
        "
        :sortable="item.hasSort || manualSort(item) ? 'custom' : item.hasSort"
        :align="item.keyName == 'budget' || item.keyName == 'product_report' ? undefined : 'right'"
        header-align="left"
      >
        <template #header>
          <span>
            <el-popover
              placement="bottom"
              :title="item.name"
              width="460"
              trigger="hover"
              :content="item.remark"
              :disabled="!item.remark"
            >
              <span slot="reference">{{ item.name }}</span>
            </el-popover>
          </span>
        </template>
        <template slot-scope="scope">
          <ad-cell-inline-edit-budget
            :value="scope"
            @scope-row-changed="refreshTableRow(scope)"
            :key="scope.row.level + scope.row.id + scope.row.name"
            v-if="item.keyName === 'budget'"
            :date-range="dateRange"
            @get-list="debouncedReloadTable"
            @settingComplete="settingComplete"
          ></ad-cell-inline-edit-budget>
          <!-- tab切换-商品经营分析/广告系列经营分析（默认广告系列经营分析）-->
          <adCellProductReport
            v-else-if="item.keyName === 'product_report'"
            :adProductReport="scope.row.adProductReport"
            :level="scope.row.level"
            :campaignId="scope.row.campaign_id"
            :adAccountId="scope.row.account_id"
            :currency_sign="
              ($store.getters.currentAccountInfo && $store.getters.currentAccountInfo.currency.key) || 'USD'
            "
            platform="facebook"
            :dateRange="dateRange"
            :key="scope.row.level + scope.row.id + scope.row.name"
          ></adCellProductReport>
          <div v-else-if="item.keyName === 'local_order'">
            <local-order
              :row="scope.row"
              :id="scope.row.id"
              :level="level"
            ></local-order>
          </div>
          <div v-else-if="item.keyName === 'local_event'">
            <div v-if="scope.row.local_event">
              <p
                class="color9"
                style="height: 20px; line-height: 20px"
                v-for="(item, index) in scope.row.local_event"
                :key="index + item.eventTypeDesc"
              >
                {{ item.eventTypeDesc }}(成本)：{{ item.eventCount }}({{ item.costPrice }})
              </p>
            </div>
            <div v-else>--</div>
          </div>

          <div v-else-if="item.keyName === 'open_schedule'">
            <div v-if="scope.row.open_schedule && scope.row.open_schedule.length > 0">
              <p
                v-for="(item, index) in scope.row.open_schedule"
                :key="index + item.timeStr"
                class="open-schedule"
              >
                <span
                  v-if="item && item.type == 1"
                  class="set-open"
                  >定时开启任务:{{ item.timeStr }}
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="cancelSet('task', scope.row, scope.$index, index)"
                    >取消</el-button
                  ></span
                >
                <span
                  v-if="item && item.type == 2"
                  class="budget-span"
                  >定时修改预算:{{ item.timeStr }}
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="cancelSet('budget', scope.row, scope.$index, index)"
                    >取消</el-button
                  ></span
                >
                <span
                  v-if="item && item.type == 2"
                  class="budget-span"
                  >预算金额：{{ item.budget }}</span
                >
              </p>
            </div>
            <p
              v-else
              class="open-schedule"
            >
              --
            </p>
          </div>
          <div v-else-if="item.keyName == 'ad_label'">
            <campaign-label
              :labelList="labelList"
              @getLabelList="getLabelList"
              :level="level"
              :info="scope"
              :index="scope.$index"
              :accountId="scope.row.rowAccountId"
              @scope-row-changed="refreshTableRow(scope)"
            ></campaign-label>
          </div>
          <div
            class="text-a-l"
            v-else-if="item.keyName === 'campaign_ref'"
          >
            <div>
              <span v-if="scope.row.campaign_ref == 'NOT_REF'">
                <el-button
                  size="mini"
                  @click="relateSaleClick(scope)"
                  >关联商品</el-button
                >
              </span>
              <span v-if="scope.row.campaign_ref == 'REF'">
                <span>已关联</span>
              </span>
              <span v-else>
                <span></span>
              </span>
            </div>
          </div>
          <span
            v-else
            v-html="formatedCellValueV2(scope.row, item)"
          ></span>
        </template>
      </el-table-column>
      <template slot="append">
        <div
          class="loadMore"
          @click="loadMoreClick"
          :style="loadText == '滚动/点击加载更多' ? 'cursor:pointer' : ''"
        >
          {{ loadText }}
        </div>
      </template>
    </el-table>
    <!-- 保存为我的筛选条件 -->
    <el-dialog
      title="保存查询条件"
      :visible.sync="saveFilterShow"
      width="500px"
    >
      <div>
        <el-form label-width="120px">
          <el-form-item label="查询条件名称">
            <el-input
              placeholder="请输入"
              v-model="filterName"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="primary"
              @click="saveMyFilter"
              >保存</el-button
            >
            <el-button
              @click="
                saveFilterShow = false;
                filterName = '';
              "
              >取消</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
    <!-- 定制列弹窗 -->
    <cus-column-dialog
      :visible="cusColumnVisible"
      :initCheckedColumns="initCheckedColumns"
      @close-dialog="cusColumnVisible = false"
      :name="myColumsName"
      @post-save-res="handleGetSaveRes"
      @post-cus-columns="handleGetCusColumns"
    ></cus-column-dialog>
    <!-- 图表抽屉 -->
    <chart-drawer
      :visible="drawers.chart.visible"
      @close-drawer="drawers.chart.visible = false"
      :currentRow="drawers.chart.currentRow"
      :list="tableData"
      :pageAfter="after"
      :pageSize="params.page_length"
      :total="adsCountObj[`${level}s`] || 0"
      :fields="fields"
      :defDateStart="params.date_start"
      :defDateEnd="params.date_end"
    ></chart-drawer>
    <!-- 复制[广告系列、广告组、广告 ]抽屉 -->
    <copy-drawer
      :visible="drawers.copy.visible"
      @close-drawer="drawers.copy.visible = false"
      :selected-row-scopes="drawers.copy.rowScopes"
      :level="drawers.copy.level"
      @reload-table="reloadTable"
    ></copy-drawer>
    <!-- 编辑广告组 -->
    <edit-ad-group
      :editGroupShow="editGroupShow"
      @close="close"
      :initData="initDataAdCreat"
      :id="editAdSetId"
      :accountId="accountId"
      :preview="false"
      @get-list="debouncedReloadTable"
      type="FB"
      :adsetScope="adsetScope"
    ></edit-ad-group>
    <!-- 编辑广告 -->
    <edit-ads
      :editAdsShow="editAdsShow"
      @close="close"
      :initData="initDataAdCreat"
      :id="editAdId"
      :accountId="accountId"
      :preview="false"
      @get-list="debouncedReloadTable"
      type="FB"
      :adScope="adScope"
    ></edit-ads>
    <!-- 编辑竞价预算 -->
    <edit-budget-price
      :editBudgetPriceShow="drawers.editBudgetPrice.visible"
      @close-drawer="drawers.editBudgetPrice.visible = false"
      :selectedRowScopes="drawers.editBudgetPrice.rowScopes"
      :level="drawers.editBudgetPrice.level"
      @refresh-table="refreshTablePrice"
    ></edit-budget-price>
    <!-- 关联商品 -->
    <related-goods
      :relateSale="relateSale"
      @close="close"
      :rowMsg="rowMsg"
      @getList="refreshTableRow"
      paltform="facebook"
    ></related-goods>
    <!-- 批量修改广告创意 -->
    <batch-edit-ads
      @close="close"
      :list="selectedRowScopes.filter((v) => v.level == 'ad')"
      :batchEditAds="batchEditAds"
      :initData="initDataAdCreat"
    ></batch-edit-ads>
    <!-- 创建广告 -->
    <edit-ads
      :editAdsShow="creatAdsShow"
      @close="close"
      :initData="initDataAdCreat"
      :id="creatAdParentId"
      :accountId="accountId"
      :preview="false"
      @get-list="debouncedReloadTable"
      type="FB"
      :adScope="adScope"
      :creat="true"
    ></edit-ads>
    <!-- 修改广告名称 -->
    <edit-ad-name
      :editNameShow="editNameShow"
      @close="close"
      nameLevel="ad"
      :list="selectedRowScopes.filter((v) => v.level == 'ad')"
      @get-list="debouncedReloadTable"
      :date-range="dateRange"
    ></edit-ad-name>
    <!-- 编辑广告组像素 -->
    <batch-edit-pixel
      :editPixelShow="editPixelShow"
      @close="close"
      :list="selectedRowScopes.filter((v) => v.level == 'adset')"
      @get-list="debouncedReloadTable"
    ></batch-edit-pixel>
    <!-- 批量编辑广告组 -->
    <batch-edit
      :batchEditShow.sync="batchEditShow"
      :list="selectedRowScopes.filter((v) => v.level == level)"
      :level="level"
      :initCreatAdData="initDataAdCreat"
      @get-list="debouncedReloadTable"
    ></batch-edit>
    <!-- 快速复制 -->
    <edit-copy
      :visible.sync="editCopyShow"
      @close-drawer="editCopyShow = false"
      :editRows="editCopyList"
      :level="level"
      @reload-table="reloadTable"
    ></edit-copy>
    <!-- 批量修改网域 -->
    <ad-url
      :editUrlShow="editUrlShow"
      @close="close"
      :list="selectedRowScopes.filter((v) => v.level == 'ad')"
      @get-list="debouncedReloadTable"
    ></ad-url>
    <!-- 定时开启 -->
    <set-time
      ref="setTimeVue"
      :adScheduleOpenTaskType="adScheduleOpenTaskType"
      :setTimeIds="setTimeIds"
      :level="level"
      :selectedRowScopes="selectedRowScopes"
      platform="facebook"
      @settingComplete="settingComplete"
    />
    <!-- 操作日志 -->
    <logDraw
      :show.sync="showLog"
      needSign
      :apiParams="{
        platform: 'facebook',
        level: level,
        campaignId: operationData.campaign_id,
        adGroupId: operationData.adset_id,
        adId: operationData.id,
      }"
    ></logDraw>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';

import AdCellInlineEditBudget from '@/views/adManagement/components/table/AdCellInlineEditBudget';
import AdCellName from '@/views/adManagement/components/table/AdCellName';
import batchEditPixel from '@/views/adManagement/components/table/batchEditPixel';
import adUrl from '@/views/adManagement/components/table/adUrl';
import DraftBox from '@/views/adManagement/components/DraftBox';
import cusColumnDialog from '@/views/adManagement/components/cusColumnDialog/index';
import chartDrawer from '@/views/adManagement/components/chartDrawer/index';
import EditAdGroup from '@/views/adManagement/createAd/components/editAdGroup.vue';
import editAds from '@/views/adManagement/createAd/components/editAd';
import editAdName from '@/views/adManagement/createAd/components/editAdName';
import editBudgetPrice from '@/views/adManagement/components/budget_price/editBudgetPrice.vue';
import downLoad from '@/views/adManagement/components/tableTopBar/downLoad.vue';
import relatedGoods from './relatedGoods';
import batchEditAds from './batchEditAds';
import batchEdit from '../batchEdit/edit';
import editCopy from '../editCopy/index';
// import campaignLabel from './campaignLabel.vue';
import { adConstsMap } from '@/api/creatAd';
import { setTimeJS } from './setTime';
import { logDraw, adCellProductReport, campaignLabel } from '@adCommonComponent';
import localOrder from '@/views/adManagement/components/table/localOrder';
import { ProductReport } from '@adCommonComponent/utils/ProductReport.js';

import {
  breakdown,
  breakdownRules,
  deleteColumn,
  deleteFilter,
  myFilters,
  report,
  reportConsts,
  saveFilter,
  myColumns,
  mutateStatus,
  alertScore,
  queryConfigLabel,
} from '@/api/adManagement';
import CopyDrawer from '@/views/adManagement/components/copy/CopyDrawer';
function Selection(ads, adsets, campaigns) {
  this.ads = ads || [];
  this.adsets = adsets || [];
  this.campaigns = campaigns || [];
}
//筛选【封装】，细分，头部操作，自定义列，弹框，放表格里
///日期选择，放index
const statusList = [
  { key: 'ACTIVE', campaign_val: 1, adset_val: 1, ad_val: 1 },
  { key: 'PAUSED', campaign_val: 7, adset_val: 9, ad_val: 8 },
  { key: 'DELETED', campaign_val: 6, adset_val: 8, ad_val: 7 },
  { key: 'PENDING_REVIEW', campaign_val: 3, adset_val: 3, ad_val: 3 },
  { key: 'DISAPPROVED', campaign_val: 2, adset_val: 2, ad_val: 2 },
  { key: 'PREAPPROVED', campaign_val: 8, adset_val: 10, ad_val: 9 },
  { key: 'PENDING_BILLING_INFO', campaign_val: 9, adset_val: 11, ad_val: 10 },
  { key: 'CAMPAIGN_PAUSED', campaign_val: 10, adset_val: 7, ad_val: 11 },
  { key: 'ARCHIVED', campaign_val: 11, adset_val: 12, ad_val: 12 },
  { key: 'ADSET_PAUSED', campaign_val: 12, adset_val: 13, ad_val: 16 },
  { key: 'IN_PROCESS', campaign_val: 13, adset_val: 4, ad_val: 4 },
  { key: 'WITH_ISSUES', campaign_val: 14, adset_val: 14, ad_val: 5 },
  { key: 'NOAD', campaign_val: 4, adset_val: 6, ad_val: 13 },
  { key: 'CLOSED', campaign_val: 5, adset_val: 4, ad_val: 14 },
];
function getIndex(key, level) {
  return statusList.filter((v) => v.key == key).length
    ? statusList.filter((v) => v.key == key)[0][`${level}_val`]
    : 100;
}
export default {
  name: 'AdTable',
  props: {
    dateRange: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
    activeLevel: {
      type: String,
      default: 'campaign',
    },
    accountId: {
      type: String,
      default: '',
    },
    initData: {
      type: Object,
      default: () => ({}),
    },
    adsCountObj: {
      type: Object,
      default: () => ({}),
    },
    selectCampaigns: {
      type: Array,
      default: () => [],
    },
    selectAdsets: {
      type: Array,
      default: () => [],
    },
    selectAds: Array,
  },
  mixins: [setTimeJS],
  data() {
    return {
      selectCampaignsOld: '',
      selectAdsetsOld: '',
      assessLoading: false,
      value2: {},
      adType: '创建标准广告',
      filterIcon: require('@/assets/icon/filter.png'),
      keyword: '',
      tableData: [],
      disAbled: false,
      // loading: false,
      adScheduleOpenTaskType: [], // 定时开启 时间
      fields: [],
      params: {
        account_id: this.currentAccount,
        action_attribution_windows: this.dateRange.actionAttributionWindows || [],
        after: '',
        date_end: this.dateRange.dateRange[1] || '2020-10-01',
        date_start: this.dateRange.dateRange[0] || '2020-02-20',
        fields: [],
        filtering: [],
        object_filtering: [],
        keyword: '',
        level: 'campaign',
        page_length: 50,
        sort: [],
      },
      after: '',
      expandAll: false, //是否展开所有下级，当加载细分的时候默认展开
      expandKeys: [],
      scrollDisable: false, //表格无限滚动是否禁用
      loadText: '滚动/点击加载更多', //默认加载文字
      breakdownRulesArr: [],
      breakdowms: {
        time: '',
        delivery: '',
      },
      breakdownGroup: [],
      maps: new Map(),
      infiniteScrollDisabled: false,
      lazy: true,
      // 搜索框
      searchInput: false,
      // 自定义列
      initCheckedColumns: [],
      cusColumnVisible: false,
      myColumnsArr: [],
      myColumsName: '',
      myColumsId: '',
      myCols: {
        myColumnsArr: [],
        myColumsName: '',
        myColumsId: '',
      },
      // 筛选条件
      filterList: [], //我的自定义筛选条件
      cusFilterList: [{ field: null, operator: '', value: null, value1: [] }],
      filterId: 0,
      filterShow: false,
      checkList: [],
      filterName: '',
      saveFilterShow: false,
      drawers: {
        chart: { visible: false, rowScopes: [] }, // 图表抽屉
        copy: { visible: false, rowScopes: [] }, // 复制抽屉
        editBudgetPrice: { visible: false, rowScopes: [], level: this.level }, // 修改竞价预算
      },
      selectedRowScopes: [],
      // 修改广告广告组
      initDataAdCreat: {},
      editGroupShow: false,
      editAdsShow: false,
      editAdSetId: 0,
      editAdId: 0,
      // 取消上一次请求
      source: null,
      downLoadParams: null,
      relateSale: false, //关联商品
      rowMsg: null,
      // 批量编辑广告创意
      batchEditAds: false,
      adScope: null,
      adsetScope: null,
      creatAdsShow: false,
      creatAdParentId: null,
      editNameShow: false,
      editPixelShow: false,
      batchEditShow: false,
      editCopyShow: false,
      editUrlShow: false,
      summary: {}, //合计行
      // 经营分析
      manageAssess: [],
      list: [],
      manualSortFlag: null,
      accountIndex: 0,
      showLog: false, // 显示操作日志
      operationData: {}, // 操作的数据
    };
  },
  components: {
    adCellProductReport,
    CopyDrawer,
    AdCellName,
    AdCellInlineEditBudget,
    DraftBox,
    cusColumnDialog,
    chartDrawer,
    EditAdGroup,
    editAds,
    editBudgetPrice,
    downLoad,
    relatedGoods,
    batchEditAds,
    editAdName,
    batchEditPixel,
    batchEdit,
    editCopy,
    adUrl,
    localOrder,
    campaignLabel,
    logDraw,
  },
  filters: {
    showName(item) {
      return item ? (item['name'] ? item['name'] : '') : '请选择';
    },
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('num', ['selectedNum', 'multiAccountList']),
    ...mapGetters('num', ['toFormatedMoneyV2', 'currentUiUnit']),
    ...mapGetters('adActiveTab', [
      'campaignDateRangOld',
      'campaignDateRangNew',
      'adsetDateRangNew',
      'adsetDateRangOld',
      'adDateRangNew',
      'adDateRangOld',
      'labelList',
    ]),
    ...mapState('initData', ['adAccountLabelList']),

    editCopyList() {
      return this.list.filter((v) => v.level == this.level);
    },
    // 获取账号列表
    accountList() {
      let list = [];
      if (this.level == 'adset' && this.selectMultiCampaignsNew && this.selectMultiCampaignsNew.length) {
        list = [this.selectMultiCampaignsNew[0].rowThirdNum + '_' + this.selectMultiCampaignsNew[0].rowAccountId];
        return list;
      } else if (this.level == 'ad' && this.selectMultiAdsetsNew && this.selectMultiAdsetsNew.length) {
        list = [this.selectMultiAdsetsNew[0].rowThirdNum + '_' + this.selectMultiAdsetsNew[0].rowAccountId];
        return list;
      } else {
        // 当前标签下选择的账户，或者上次选择标签下的全部，或者第一个标签下的全部
        if (this.multiAccountList.length) {
          list = this.multiAccountList;
        } else {
          let activeLabel = localStorage.getItem('multiAccountLabel') || this.adAccountLabelList[0].id;
          let index =
            this.adAccountLabelList.findIndex((v) => v.id == activeLabel) != -1
              ? this.adAccountLabelList.findIndex((v) => v.id == activeLabel)
              : 0;
          list = this.adAccountLabelList[index].list;
        }
        let list1 = list.length
          ? Array.from(new Set(list.map((v) => v.id)))
          : [this.selectedNum + '_' + this.accountId];
        console.log(list, list1, 'list');
        let arr = [];
        list1.map((v) => {
          if (arr.every((k) => k != v)) {
            arr.push(v);
          }
        });
        this.$emit('setMulitAccount', arr);
        return arr;
      }
    },
    // 获取当前的账号
    currentAccount() {
      return this.accountList[this.accountIndex];
    },
    // 多账号加载取所有账号
    accountListMap: {
      get() {
        let accountMap = new Object();
        this.accountList.map((v) => {
          let obj = {
            after: '',
            loadedFinish: false,
          };
          accountMap[v] = obj;
        });
        return accountMap;
      },
    },
  },
  watch: {
    accountId: function (v, ov) {
      this.debouncedReloadTable();
    },
    dateRange: {
      handler(val) {
        this.setDateRangeParam();
        this.breakdownRules();
      },
      deep: true,
    },
    multiAccountList: {
      handler() {
        this.debouncedReloadTable();
      },
      deep: true,
    },
  },
  async mounted() {
    this.downLoadParams = JSON.parse(JSON.stringify(this.params));
    await this.myColumns();
    this.getMyFilter();
    this.breakdownRules();
    // await this.getInitData();
    this.debouncedReloadTable();
    // 获取广告创建常量(后续优化放到编辑广告组，编辑广告里面)
    this.getInitDataAdCreat();
    await this.getLabelList();
  },
  // 注册自定指令
  directives: {
    'infinite-scroll': {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        selectWrap.addEventListener('scroll', function () {
          if (selectWrap.scrollHeight == selectWrap.clientHeight) return;
          let sign = binding.value.distance;
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value.load();
          }
        });
      },
    },
  },
  updated() {
    this.$refs['table'].doLayout();
  },
  methods: {
    ...mapMutations(['audiences/setMultipleAdsets']),

    // 已选择系列广告账号跟当前不同，则禁选
    canSelect(row, index) {
      if (
        !this.selectedRowScopes.length ||
        this.selectedRowScopes.map((v) => v.rowAccountId).includes(row.rowAccountId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    alertScore({ id, score }) {
      let params = {
        id,
        score,
      };
      alertScore(params).then((res) => {
        if (res.code == 0) {
        }
      });
    },
    reviewScore({ curTableRow, curTableIndex, curRow, curIndex, flag }) {
      if (curTableRow.level == this.level) {
        this.$refs.table.store.states.data.map((k, i) => {
          if (k.id == curTableRow.id) {
            this.$refs.table.store.states.data[i].status_hints[curIndex].alertScore = flag;
          }
        });
      } else {
        // 不是当前级别
        let parentId = curTableRow.level == 'adset' ? curTableRow.campaign_id : curTableRow.adset_id;
        let list = this.$refs.table.store.states.lazyTreeNodeMap[parentId];
        list.map((v, index) => {
          if (v.id == curTableRow.id) {
            this.$refs.table.store.states.lazyTreeNodeMap[parentId][index].status_hints[curIndex].alertScore = flag;
          }
        });
      }
      this.alertScore({ id: curRow.alertId, score: flag });
    },
    tabChangeReloadTable(level) {
      if (level == 'campaign') {
        if (JSON.stringify(this.campaignDateRangNew) !== JSON.stringify(this.campaignDateRangOld)) {
          this.$store.commit('adActiveTab/setCampaignDateRangOld', this.dateRange);
          this.debouncedReloadTable();
          this.setSel();
        }
      }

      if (level == 'adset') {
        if (
          JSON.stringify(this.selectCampaignsOld) !=
            JSON.stringify(this.selectCampaigns.map((item) => item.campaign_id)) ||
          JSON.stringify(this.adsetDateRangNew) != JSON.stringify(this.adsetDateRangOld)
        ) {
          this.debouncedReloadTable();
          this.setSel();
        }
      }

      if (level == 'ad') {
        if (
          JSON.stringify(this.selectCampaignsOld) !=
            JSON.stringify(this.selectCampaigns.map((item) => item.campaign_id)) ||
          JSON.stringify(this.selectAdsetsOld) != JSON.stringify(this.selectAdsets.map((item) => item.adset_id)) ||
          JSON.stringify(this.adDateRangNew) !== JSON.stringify(this.adDateRangOld)
        ) {
          this.debouncedReloadTable();
        }
      }

      this.selectCampaignsOld = this.selectCampaigns.map((item) => item.campaign_id);
      this.selectAdsetsOld = this.selectAdsets.map((item) => item.adset_id);
    },
    rowClick(row, column, event) {
      if (column && column.label && column.label != '预算') {
        this.$refs.table.toggleRowSelection(row);
      }
    },
    // 选中状态回显
    setSel() {
      let sel = [];
      if (this.level == 'adset') {
        sel = this.selectAdsets || [];
      }

      sel.forEach((item) => {
        let obj = this.tableData.find((row) => row.id == item.id);
        if (obj) {
          this.$refs.table.toggleRowSelection(obj, true);
        }
      });
    },
    getAdsetReport(adsetInfo, isChild, campaginInfo) {
      if (!isChild) {
        const sel = this.selectCampaigns || [];
        campaginInfo = sel.find((s) => s.campaign_id == adsetInfo.campaign_id);
      }
      const adProductReport = campaginInfo?.adProductReport;
      if (campaginInfo && adProductReport && adsetInfo.local_order?.validOrderCount) {
        let purchase = adsetInfo.local_order.validOrderCount;
        let moneyRate = adProductReport.adCost / campaginInfo.spend;
        let spend = adsetInfo.spend * moneyRate;
        return new ProductReport(adProductReport, purchase, spend);
      }
      return null;
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        return 'height:60px!important;';
      }
    },
    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2) {
        return 'header-status';
      }
    },
    // 关联商品
    relateSaleClick(row) {
      this.rowMsg = row;
      this.relateSale = true;
    },
    cancelQuest() {
      if (typeof this.source === 'function') {
        this.source(); //取消请求
      }
    },
    specialFields(params) {
      if (params.level === 'ad') {
        return ['creative_thumbnail'];
      }
      if (params.fields.indexOf('product_report') >= 0) {
        if (params.fields.indexOf('local_order') >= 0) {
          if (params.fields.indexOf('spend') > 0) {
            return ['adProductReport'];
          } else {
            return ['adProductReport', 'spend'];
          }
        } else {
          return ['adProductReport', 'local_order'];
        }
      }
      return [];
    },
    // 获取广告创建常量(编辑的时候会用到)
    getInitDataAdCreat() {
      adConstsMap().then((res) => {
        this.initDataAdCreat = res.data;
        this.adScheduleOpenTaskType = this.initDataAdCreat?.ad?.AdScheduleOpenTaskType;
        this.$store.commit('initData/setInitData', res.data);
      });
    },
    close(v) {
      this[v] = false;
    },
    openChart(v) {
      this.drawers.chart.visible = true;
      this.drawers.chart.currentRow = v;
    },
    setDateRangeParam() {
      this.params.action_attribution_windows = this.dateRange.actionAttributionWindows;
      this.params.date_start = this.dateRange.dateRange[0];
      this.params.date_end = this.dateRange.dateRange[1];
      if (this.level == 'campaign') {
        this.$store.commit('adActiveTab/setCampaignDateRangNew', this.dateRange);
      }
      if (this.level == 'adset') {
        this.$store.commit('adActiveTab/setAdsetDateRangNew', this.dateRange);
      }
      if (this.level == 'ad') {
        this.$store.commit('adActiveTab/setAdDateRangNew', this.dateRange);
      }
      this.debouncedReloadTable();
      if (this.level == 'campaign') {
        this.$store.commit('adActiveTab/setCampaignDateRangOld', this.dateRange);
      }
      if (this.level == 'adset') {
        this.$store.commit('adActiveTab/setAdsetDateRangOld', this.dateRange);
      }
      if (this.level == 'ad') {
        this.$store.commit('adActiveTab/setAdDateRangOld', this.dateRange);
      }
    },
    formatedCellValue(row, columnDefine) {
      let keyName = columnDefine.keyName;
      let type = columnDefine.type;
      let v = row[keyName];
      let empty = false;
      if (v === undefined || v === null || v == 0) empty = true;
      switch (type) {
        case 'STRING':
          if (empty) return '-';
          return v;
        case 'MONEY':
          if (empty) {
            // if(keyName=='cost_per_action_type'){
            //     return `<p style="font-size:15px;color:#606770;">-</p><p class="color9">购买</p>`;
            // }else{
            return '-';
            // }
          } else {
            return this.actionValueDesc(keyName, this.formatedMoney(v, true), row);
          }
        case 'PERCENTAGE':
          if (empty) return '-';
          return parseFloat(v).toFixed(2) + '%';
        case 'INT':
          if (empty) {
            // if(keyName=='object_actions'){
            //     return `<p style="font-size:15px;color:#606770;">-</p><p class="color9">购买</p>`;
            // }else{
            return '-';
            // }
          } else {
            return this.actionValueDesc(keyName, parseInt(v), row);
          }
        case 'DECIMAL':
          if (empty) return '-';
          return parseFloat(v).toFixed(2);
        default:
          return v;
      }
    },
    formatedCellValueV2(row, columnDefine) {
      let keyName = columnDefine.keyName,
        type = columnDefine.type,
        v = row[keyName],
        empty = false;
      if (v === undefined || v === null || v == 0) empty = true;
      let val = '--',
        desc = '',
        obj = {};
      if (!empty) {
        switch (type) {
          case 'MONEY':
            obj = this.actionValueDescV2(keyName, this.formatedMoney(v, keyName !== 'bid_amount'), row);
            val = obj.val;
            desc = obj.desc;
            break;
          case 'PERCENTAGE':
            val = parseFloat(v).toFixed(2) + '%';
            break;
          case 'INT':
            obj = this.actionValueDescV2(keyName, parseInt(v), row);
            val = obj.val;
            desc = obj.desc;
            break;
          case 'DECIMAL':
            val = parseFloat(v).toFixed(2);
            break;
          default:
            val = v;
            break;
        }
      }
      let str = `<p style="font-weight:700;">${
        val || '--'
      }</p><p class="color9" style="height:20px;line-height:20px;">${desc}</p>`;
      return str;
    },
    actionValueDesc(field, value, row) {
      switch (field) {
        case 'cost_per_action_type':
          if (row[field + '_desc']) {
            // return value+'/'+row[field+'_desc']
            return `${value || '-'}${row[field + '_desc']}`;
          }
        case 'object_actions':
          if (row[field + '_desc']) {
            // return value+row[field+'_desc']
            return `${value || '-'}${row[field + '_desc']}`;
          }
      }
      return value;
    },
    actionValueDescV2(field, value, row) {
      if (field == 'cost_per_action_type' || field == 'object_actions') {
        if (row[field + '_desc']) {
          return {
            val: value || '--',
            desc: row[field + '_desc'],
          };
        }
      } else {
        return {
          val: value,
          desc: '',
        };
      }
    },
    formatedMoney(v, base) {
      return this.$store.getters.toFormatedMoney(v, base).formated;
      // return "$" + (parseFloat(v) / 100).toFixed(2);
    },
    // 批量操作
    //
    // 点击编辑
    handleEditCommand(v) {
      // 编辑广告系列竞价预算
      if (v.type == 'campaign' || v.type == 'adset') {
        this.drawers.editBudgetPrice.visible = true;
        if (v.scope) {
          // 单个
          this.drawers.editBudgetPrice.rowScopes = [v.scope.row];
          this.drawers.editBudgetPrice.level = v.scope.row.level;
        } else {
          // 批量
          this.drawers.editBudgetPrice.rowScopes = this.selectedRowScopes;
          this.drawers.editBudgetPrice.level = this.level;
        }
      }
      // // 编辑广告组竞价预算
      // if(v.type == 'adset'){
      //     this.drawers.editBudgetPrice.visible = true;
      //     if(v.scope){
      //         // 单个
      //         this.drawers.editBudgetPrice.rowScopes = [v.scope];
      //     }else{
      //         // 批量
      //         this.drawers.editBudgetPrice.rowScopes = this.selectedRowScopes;
      //     }
      // }
      // 编辑广告组
      if (v.type == 'editAdset') {
        this.editGroupShow = true;
        this.editAdSetId = v.scope.row.id;
        this.adsetScope = v.scope;
      }
      // 编辑广告
      if (v.type == 'editAd') {
        this.editAdsShow = true;
        this.editAdId = v.scope.row.id;
        this.adScope = v.scope;
      }
      // 创建广告
      if (v.type == 'creatAd') {
        this.creatAdsShow = true;
        this.adScope = v.scope;
        this.creatAdParentId = v.scope.row.id;
      }
      // 批量修改广告创意
      if (v.type == 'ad') {
        this.batchEditAds = true;
      }
      // 批量修改广告名称
      if (v.type == 'adName') {
        this.editNameShow = true;
      }
      // 批量修改广告网域
      if (v.type == 'adUrl') {
        this.editUrlShow = true;
      }
      // 批量修改像素
      if (v.type == 'adsetPix') {
        this.editPixelShow = true;
      }
      // 批量修改广告组
      if (v.type == 'adsets') {
        this.batchEditShow = true;
      }
      // 批量编辑广告创意
      if (v.type == 'editAds') {
        this.batchEditShow = true;
      }
    },
    // 点击复制
    handleCopyCommand(v) {
      this.showDrawer('copy', this.selectedRowScopes, v);
    },
    // field
    operatorName(v, tag) {
      return v
        ? v.filtering.filter((m) => m.key == tag.operator)[0]
          ? v.filtering.filter((m) => m.key == tag.operator)[0].desc
          : ''
        : '请选择';
    },
    // 显示筛选条件符号下拉
    showFiltering(tag) {
      let list = this.initData.FilterFields.filter((v) => v.keyName == tag.field);
      if (list.length) {
        return list[0].filtering;
      } else {
        return [];
      }
    },
    // 显示筛选条件的值
    valueName(tag) {
      let list = [];
      tag.value1.map((item) => {
        if (tag.field == 'objective') {
          this.initData.CampaignObjective.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        } else if (tag.field == 'status') {
          this.initData.FilteringStatus.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        } else {
          this.labelList.map((v) => {
            if (item == v.labelId) {
              list.push(v.name);
            }
          });
        }
      });
      return list ? list : '请选择';
    },
    // checkList

    // 获取常量数据
    async getInitData() {
      await reportConsts().then((res) => {
        this.initData = res.data;
      });
    },
    // 获取我之前定制的列组合
    async myColumns() {
      const params = { accountId: this.accountId };
      await myColumns(params).then((res) => {
        if (res.code == 0) {
          this.myCols.myColumnsArr = res.data;
          let myColumsId = localStorage.getItem('myColumsId_' + this.level);
          if (myColumsId) {
            this.fields = JSON.parse(localStorage.getItem('myColums_' + this.level));
          } else {
            this.fields = res.data[0].columns;
          }
          this.myCols.myColumsId = myColumsId;
        }
      });
    },
    showDrawer(drawerName, rowScopes, level) {
      for (let k in this.drawers) {
        this.drawers[k].visible = k === drawerName;
        this.drawers[k].rowScopes = JSON.parse(JSON.stringify(rowScopes.filter((v) => v.level == level)));
        this.drawers[k].level = level;
      }
    },
    // 切换筛选条件
    handleFilterCommand(v) {
      this.filterShow = true;
      let filtering = JSON.parse(JSON.stringify(v.filtering));
      this.params.filtering = filtering.map((v) => {
        if (v.field == 'objective' || v.field == 'status' || v.field == 'ad_label') {
          v.value1 = v.value;
        }
        return v;
      });
      this.saveFilterStorage();
      this.debouncedReloadTable();
    },
    // 监听筛选条件value的变换
    valueChange(v) {
      this.saveFilterStorage();
      this.debouncedReloadTable();
    },
    objectFilteringBySection() {
      let filteringCampaignIds = [];
      let filteringAdseIds = [];
      if (this.selectCampaigns && this.selectCampaigns.length > 0) {
        let obj = {
          field: 'campaign.id',
          operator: 'IN',
          value: this.selectCampaigns.map((v) => v.id),
        };
        filteringCampaignIds.push(obj);
      }
      if (this.selectAdsets && this.selectAdsets.length > 0) {
        let obj = {
          field: 'adset.id',
          operator: 'IN',
          value: this.selectAdsets.map((v) => v.id),
        };
        filteringAdseIds.push(obj);
      }
      switch (this.level) {
        case 'adset':
          return filteringCampaignIds;
        case 'ad':
          return filteringCampaignIds.concat(filteringAdseIds);
      }
      return [];
    },
    reloadTable() {
      //初始化，或刷新
      document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
      this.after = '';
      this.currentAfter = '';
      // this.setDateRangeParam();
      // this.infiniteScrollDisabled = true;
      console.log(this.params, 'this.params');
      let params = JSON.parse(JSON.stringify(this.params));
      // this.$showLoading();
      // this.loading = true;
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = ['name', 'status', 'id', 'campaign_ref', 'campaign_id', 'adset_id', 'purchase'].concat(filedList);
      this.accountIndex = 0;
      params.account_id = this.currentAccount;
      this.$set(params, 'account_id', this.currentAccount);
      // console.log(params.level == this.level);
      console.log(params, this.currentAccount, this.accountIndex, this.accountList, 'this.accountList');

      params.after = '';
      // 处理筛选条件
      let filtering = JSON.parse(JSON.stringify(params.filtering));
      filtering.map((item) => {
        if (item.field == 'status' || item.field == 'objective' || item.field == 'ad_label') {
          item.value = item.value1 ? JSON.parse(JSON.stringify(item.value1)) : item.value;
        }
        delete item.value1;
      });
      // 从filtering 里面取出object_filtering 的项
      let object_filtering = filtering.filter(
        (v) =>
          v.field == 'status' ||
          (v.field == 'objective' && v.value.length) ||
          (v.field == 'ad_label' && v.value.length),
      );
      params.object_filtering = object_filtering.concat(this.objectFilteringBySection());
      params.filtering = filtering.filter(
        (v) => v.field != 'status' && v.field != 'objective' && v.field != 'ad_label',
      );
      // console.log('params',params);
      params.level = this.level;
      if (this.level !== this.activeLevel) {
        return false;
      }
      this.downLoadParams = JSON.parse(JSON.stringify(params));
      this.tableData = [];
      return this.getList(params).then(async (res) => {
        this.tableData = res;
        if (!params.sort.length) {
          this.tableData.sort(this.compare('statusIndex', 'ascending'));
        }
        if (res.length < params.page_length && this.accountIndex == this.accountList.length - 1) {
          // 当前账号加载完成，并且是最后一个账号
          this.accountListMap[this.currentAccount].loadedFinish = true;
          this.loadText = '没有更多数据了';
          this.infiniteScrollDisabled = true;
        } else if (res.length < params.page_length && this.accountIndex < this.accountList.length - 1) {
          // 当前账号加载完成，下一次加载下一个账号
          this.accountListMap[this.currentAccount].loadedFinish = true;
          this.accountIndex += 1;
          this.currentAfter = '';
          this.infiniteScrollDisabled = false;
          if (this.tableData.length < 50) {
            this.loadMore();
          }
        }
        // 清除掉子项缓存
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          delete this.$refs.table.store.states.lazyTreeNodeMap[v];
        });
        this.tableData.map((v) => {
          if (this.$refs.table.store.states.treeData[v]) {
            this.$refs.table.store.states.treeData[v].loaded = false;
            this.$refs.table.store.states.treeData[v].expanded = false;
            this.$refs.table.store.states.treeData[v].children = [];
            this.$set(this.$refs.table.store.states.treeData, v, this.$refs.table.store.states.treeData[v]);
          }
        });
        this.clearChidren(this.tableData.map((v) => v.id));
        this.$forceUpdate();
        return;
      });
    },
    // 和几行数据
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.map((item, index) => {
        if (index == 0) {
          sums[index] = '';
          return;
        } else if (index == 1) {
          sums[index] = `${this.tableData.length}条${
            this.level == 'campaign' ? '广告系列' : this.level == 'adset' ? '广告组' : '广告'
          }的成效`;
        } else {
          let row = this.fields.filter((v) => v.keyName == item.property)[0];
          if (row) {
            let obj = {
              impressions: 0,
              inline_link_clicks: 0,
              spend: 0,
              purchase_roas: '',
            };
            this.tableData.forEach((item) => {
              obj.impressions += item.impressions / 1 || 0;
              obj.inline_link_clicks += item.inline_link_clicks / 1 || 0;
              obj.spend += item.spend / 1 || 0;
              obj.purchase_roas += item.purchase_roas / 1 || 0;
            });
            obj.inline_link_click_ctr = (obj.inline_link_clicks / obj.impressions) * 100;
            obj.cost_per_inline_link_click = obj.spend / obj.inline_link_clicks;
            sums[index] = this.formatedCellValue(obj, row);
          } else {
            sums[index] = '--';
          }
        }
      });
      return sums;
    },
    // 删除当前筛选条件
    deleFilterTag(tag, i) {
      this.params.filtering.splice(i, 1);
      if (!this.params.filtering.length) {
        this.filterShow = false;
      }
      if (tag.field == 'status') {
        this.checkList = [];
      }
      tag.value1 = tag.value1 ? tag.value1 : [];
      if (tag.field && tag.operator && (tag.value !== '' || tag.value1.length)) {
        this.saveFilterStorage();
        this.debouncedReloadTable();
      }
    },
    // 监听筛选条件field的变化
    fieldChange(list, v, i) {
      let obj = JSON.parse(JSON.stringify(v));
      if (obj.field == 'status' || obj.field == 'objective' || obj.field == 'ad_label') {
        obj.operator = 'IN';
      } else if (obj.field == 'account_name' || obj.field == 'campaign_name' || obj.field == 'adset_name') {
        obj.operator = 'CONTAIN';
      } else {
        obj.operator = '';
      }
      obj.value = '';
      obj.value1 = [];
      this.$set(list, i, obj);
    },
    // 删除筛选条件
    deleFilterObj(i) {
      this.cusFilterList.splice(i, 1);
    },
    // 控制筛选条件下拉不被隐藏
    showFilterDrop() {
      this.$nextTick((res) => {
        this.$refs.filterDrop.dropdown.show();
      });
    },
    // 添加筛选条件
    addFilter() {
      let cusFilterList = this.cusFilterList;
      let flag = true;
      cusFilterList.map((item) => {
        if (!item.field || !item.operator || (!item.value && !item.value1.length)) {
          this.$message({
            type: 'warning',
            message: '请填写完整的信息',
          });
          flag = false;
        } else {
        }
      });
      if (flag) {
        this.cusFilterList.push({
          field: '',
          operator: '',
          value: '',
          value1: [],
        });
      }
      return flag;
    },
    // 添加当前筛选条件
    addTag() {
      let obj = { field: '', operator: '', value: '', value1: [] };
      this.params.filtering.push(obj);
    },
    // 保存为我的筛选条件
    saveMyFilter() {
      let data = {
        name: this.filterName,
      };
      if (!this.filterName) {
        this.$message({
          type: 'warning',
          message: '请输入筛选条件名称',
        });
        return false;
      }
      let filters = this.params.filtering;
      if (!filters.length) {
        this.$message({
          type: 'warning',
          message: '请添加筛选条件再保存',
        });
        return false;
      }
      data.filtering = filters;
      this.$showLoading();
      data.filtering.map((v) => {
        v.value = v.value1 && v.value1.length ? v.value1 : v.value;
      });
      // this.loading=true
      saveFilter(JSON.stringify(data)).then((res) => {
        this.$hideLoading();
        // this.loading=false
        if (res.code == 0) {
          this.saveFilterShow = false;
          this.filterName = '';
          this.getMyFilter();
        }
      });
    },
    // 关闭当前的筛选条件
    closeMyFilter() {
      this.filterShow = false;
      this.cusFilterList = [{ field: '', operator: '', value: '', value1: [] }];
      this.params.filtering = [];
      this.checkList = [];
      this.saveFilterStorage();
      this.debouncedReloadTable();
    },
    // 保存为当前的筛选条件
    saveFilterSearch() {
      if (this.addFilter()) {
        this.filterShow = true;
        this.params.filtering = JSON.parse(JSON.stringify(this.cusFilterList)).slice(0, this.cusFilterList.length - 1);
        if (this.params.filtering.filter((v) => v.field == 'status').length) {
          this.checkList = this.params.filtering.filter((v) => v.field == 'status')[0].value1;
        }
        this.saveFilterStorage();
        this.debouncedReloadTable();
      }
    },
    // 删除我的查询条件
    deleFilterGroup(v) {
      let formData = new FormData();
      formData.append('id', v);
      this.$showLoading();
      // this.loading=true
      deleteFilter(formData).then((res) => {
        this.$hideLoading();
        // this.loading=false
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getMyFilter();
        }
      });
    },
    // 获取我的查询条件
    getMyFilter() {
      myFilters().then((res) => {
        this.filterList = res.data;
      });
    },
    // 定制列
    handleColumnCommand(v) {
      if (v == '定制列组合') {
        this.cusColumnVisible = true;
        this.myColumsName = '';
        this.initCheckedColumns = this.fields.map((v) => v.keyName);
      } else {
        // localStorage.setItem('myColumsId', v.id);
        localStorage.setItem('myColumsId_' + this.level, v.id);
        this.setColumsStorge(v.columns);
        this.myCols.myColumsId = v.id;
        this.fields = v.columns;
        this.debouncedReloadTable();
      }
    },
    // 编辑定制列
    editColumns(v) {
      this.cusColumnVisible = true;
      this.myColumsName = v.name;
      this.initCheckedColumns = v.columns.map((v) => v.keyName);
    },
    // 删除定制列
    deleColumns(v) {
      let formData = new FormData();
      formData.append('id', v.id);
      deleteColumn(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.myColumns();
        }
      });
    },
    // 保存当前列组合
    handleGetCusColumns(arr) {
      // 重新渲染表格
      this.fields = arr;
      this.params.fields = arr;
      this.setColumsStorge(arr);
      this.debouncedReloadTable();
    },
    // 保存列组合，以及我的列组合
    handleGetSaveRes(res) {
      // 重新渲染定制列下拉框数据
      this.fields = res;
      this.params.fields = res;
      this.setColumsStorge(res);
      this.fields = JSON.parse(localStorage.getItem('myColums_' + this.level));
      // 重新渲染表格
      this.debouncedReloadTable();
      this.myColumns();
    },
    setColumsStorge(arr) {
      localStorage.setItem('myColums_' + this.level, JSON.stringify(arr));
    },
    // 关键词变化
    keywordChange() {
      this.debouncedReloadTable();
    },
    // 自定义排序
    compare(propertyName, sort) {
      return function (obj1, obj2) {
        var value1 = obj1[propertyName] || 0;
        var value2 = obj2[propertyName] || 0;
        if (typeof value1 === 'string' && typeof value2 === 'string') {
          const res = value1.localeCompare(value2, 'zh');
          return sort === 'ascending' ? res : -res;
        } else {
          if (value1 <= value2) {
            return sort === 'ascending' ? -1 : 1;
          } else if (value1 > value2) {
            return sort === 'ascending' ? 1 : -1;
          }
        }
      };
    },
    manualSort(item) {
      let key = item.keyName || item.prop;
      return (
        key == 'add_payment_info' ||
        key == 'add_payment_info_cost' ||
        key == 'add_to_cart' ||
        key == 'add_to_cart_cost' ||
        key == 'add_to_cart_web' ||
        key == 'add_to_cart_web_cost' ||
        key == 'content_view_web' ||
        key == 'content_view_web_cost' ||
        key == 'credit_spent' ||
        key == 'fb_pixel_add_payment_info' ||
        key == 'fb_pixel_initiate_checkout' ||
        key == 'initiated_checkout_web_cost' ||
        key == 'initiate_checkout' ||
        key == 'initiate_checkout_cost' ||
        key == 'landing_page_view' ||
        key == 'like' ||
        key == 'link_click' ||
        key == 'photo_view' ||
        key == 'post' ||
        key == 'purchase' ||
        key == 'purchase_cost' ||
        key == 'purchase_web' ||
        key == 'purchase_web_cost' ||
        key == 'purchase_web_value' ||
        key == 'video_view' ||
        key == 'view_content' ||
        key == 'view_content_cost' ||
        key == 'local_order' ||
        key == 'effective_status'
      );
    },
    // 监听表格排序
    sortChange(v) {
      // 判断是否前端排序
      if (v.prop == 'name') {
        this.manualSortFlag = null;
      } else if (this.manualSort(v)) {
        if (v.prop == 'local_order') {
          this.tableData.sort(this.compare('localsOrderNum', v.order));
        } else if (v.prop == 'effective_status') {
          this.tableData.sort(this.compare('statusIndex', v.order));
        } else {
          this.tableData.sort(this.compare(v.prop, v.order));
        }
        this.$refs.table.doLayout();
        this.manualSortFlag = v;
      } else {
        this.manualSortFlag = null;
        let obj = {
          field: v.prop,
          direction: v.order ? (v.order == 'ascending' ? 'ASC' : 'DESC') : '',
        };
        this.params.sort = [obj].filter((v) => v.direction);
        this.debouncedReloadTable();
      }
    },
    // 点击创建广告类型
    handleCommandClick() {
      this.handleCommand(this.adType);
    },
    handleCommand(v) {
      this.adType = v;
      if (v === '创建标准广告') {
        this.createStandardAd({ name: 'CreateAd' });
      } else {
        this.createStandardAd({ name: 'createFastAd' });
      }
    },
    // 跳转路由
    createStandardAd(router) {
      this.$router.push(router);
    },
    // 表格表头筛选
    selectStatus(v) {
      let flag = this.params.filtering.filter((v) => v.field == 'status').length;
      if (this.params.filtering.filter((m) => m.field == 'status').length) {
        this.params.filtering.map((item, i) => {
          if (item.field == 'status') {
            item.value1 = v;
            item.value = '';
            this.$set(this.params.filtering, i, item);
          }
        });
      } else {
        let obj = { field: 'status', operator: 'IN', value1: v, value: '' };
        this.params.filtering.push(obj);
      }
      this.debouncedReloadTable();
    },
    copyRow(scope) {
      this.list = [scope.row];
      this.editCopyShow = true;
      // this.showDrawer("copy", [scope.row], scope.row.level);
    },
    // 修改状态
    setStatus(scope, status) {
      // 单个删除 传scope status 批量删除 传status  批量开启暂停传 status  单个开启暂停传scope
      let params = {
        id: [],
        status: '',
        level: this.level,
      };
      let rowThirdNum;
      if (scope) {
        let row = scope.row;
        rowThirdNum = scope.row.rowThirdNum;
        params.id = [row.id];
        params.status = status ? status : row.status;
        params.account_id = scope.row.rowAccountId;
      } else {
        params.id = this.selectedRowScopes.map((v) => v.id);
        rowThirdNum = this.selectedRowScopes[0].rowThirdNum;
        params.status = status;
        params.account_id = this.selectedRowScopes[0].rowAccountId;
      }
      if ((scope && status) || (!scope && status)) {
        this.$confirm('确定要操作当前广告?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.setStatusEvent(scope, status, params, rowThirdNum);
          })
          .catch(() => {});
      } else {
        this.setStatusEvent(scope, status, params, rowThirdNum);
      }
    },
    setStatusEvent(scope, status, params, rowThirdNum) {
      mutateStatus(JSON.stringify(params), rowThirdNum).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '成功',
          });
          if (scope) {
            if (status) {
              // 单个删除
              this.clearChidren(params.id);
              this.debouncedReloadTable();
            } else {
              // 单个开启暂停
              scope.row.effective_status = scope.row.status;
              this.clearChidren(params.id);
            }
          } else {
            if (status == 'DELETED') {
              // 批量删除
              this.clearChidren(params.id);
              this.debouncedReloadTable();
            } else {
              // 批量开启暂停
              this.selectedRowScopes.map((v) => (v.status = v.effective_status = status));
              // this.clearChidren(params.id)
            }
          }
          this.clearChidren(params.id);
        } else {
          this.$message({
            type: 'warning',
            message: '失败了',
          });
          if (scope) {
            scope.row.status = scope.row.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
          }
        }
      });
    },
    // 清除行的子项
    clearChidren(list) {
      list.map((k) => {
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          if (v == k) {
            delete this.$refs.table.store.states.lazyTreeNodeMap[v];
          }
        });
        if (this.$refs.table.store.states.treeData[k]) {
          this.$refs.table.store.states.treeData[k].loaded = false;
          this.$refs.table.store.states.treeData[k].expanded = false;
          this.$refs.table.store.states.treeData[k].children = [];
          this.$set(this.$refs.table.store.states.treeData, k, this.$refs.table.store.states.treeData[k]);
        }
        this.tableData.map((item, index) => {
          if (item.id == k) {
            item.hasChildren = true;
            this.$refs.table.toggleRowExpansion(item, false);
            this.$set(this.tableData, index, item);
          }
        });
      });
    },
    // 批量修改竞价预算之后刷新列表部分值
    refreshTablePrice(list) {
      this.clearChidren(list);
      let listNew = JSON.parse(JSON.stringify(list));
      listNew.map((item) => {
        this.$refs.table.store.states.data.map((v, i) => {
          if (v.id == item.id) {
            item.daily_budget ? (v.daily_budget = item.daily_budget * 100) : null;
            item.lifetime_budget ? (v.lifetime_budget = item.lifetime_budget * 100) : null;
            item.bid_amount ? (v.bid_amount = item.bid_amount * 100) : null;
          }
        });
      });
    },
    // 刷新当前行
    refreshTableRow(scope) {
      let row = scope.row;
      // 如果存在子级重新加载子级
      let obj = this.maps.get(row.id);
      if (obj) {
        const { tree, treeNode, resolve } = this.maps.get(row.id);
        // this.$set(this.$refs.table.store.states.lazyTreeNodeMap, row.id, []);
        this.$refs.table.store.states.treeData[row.id].loaded = false;
        this.$refs.table.store.states.treeData[row.id].expanded = false;
      } else {
      }
      // this.$refs.table.store.states.treeData[row.id].loaded = false;
      // this.$refs.table.store.states.treeData[row.id].expanded = false;
      // this.$set(this.$refs.table.store.states.lazyTreeNodeMap, row.id, []);
      this.$forceUpdate();
      // this.$set(this.tableData, scope.$index, row);
    },
    // 细分选项
    selectBreakdown(v) {
      let str = '';
      for (let key in this.breakdowms) {
        if (this.breakdowms[key]) {
          str += ',' + this.breakdowms[key];
        }
      }
      this.breakdownGroup = str.split(',');
      this.breakdownGroup.map((item, index) => {
        if (!item) {
          this.breakdownGroup.splice(index, 1);
        }
      });
      this.breakdownAds(this.tableData);
    },
    // expandChange 切换表格的展开项
    expandChange(row, expanded) {
      if (
        !this.breakdownGroup.length &&
        !this.$refs.table.store.states.lazyTreeNodeMap[row.id] &&
        expanded &&
        row.level != 'ad'
      ) {
        if (this.maps.get(row.id)) {
          const { tree, treeNode, resolve } = this.maps.get(row.id);
          // this.loadChildren(tree, treeNode, resolve);
        }
      }
    },
    // 加载列表的细分项
    breakdownAds(tableList) {
      let { action_attribution_windows, date_start, date_end, fields, level } = this.params;
      let params = {
        action_attribution_windows,
        date_start,
        date_end,
        fields,
        level,
      };
      // params.breakdowns = this.breakdownGroup;
      if (this.breakdownGroup.length) {
        this.breakdownGroup.map((item, index) => {
          this.breakdownRulesArr.map((o) => {
            o.rules.map((v) => {
              if (v.value == item && v.disabled) {
                this.breakdownGroup.splice(index, 1);
                this.breakdowms[o.by] = '';
              }
            });
          });
        });
      }
      if (this.breakdownGroup.length) {
      } else {
        this.$set(this.$refs.table.store.states, 'lazy', true);
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          delete this.$refs.table.store.states.lazyTreeNodeMap[v];
        });
        for (let key in this.$refs.table.store.states.treeData) {
          this.$refs.table.store.states.treeData[key].loaded = false;
          this.$refs.table.store.states.treeData[key].expanded = false;
          this.$refs.table.store.states.treeData[key].children = [];
          this.$set(this.$refs.table.store.states.treeData, key, this.$refs.table.store.states.treeData[key]);
        }
        this.tableData.map((item, index) => {
          item.hasChildren = true;
          this.$refs.table.toggleRowExpansion(item, false);
          this.$set(this.tableData, index, item);
        });
        // this.$refs.table.doLayout();
        return false;
      }
      params.account_id = this.accountId;
      params.ids = tableList.map((item) => item.id);
      params.level = this.level;
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = ['name', 'status', 'id', 'campaign_ref', 'campaign_id', 'adset_id', 'purchase'].concat(filedList);
      params.breakdowns = this.breakdownGroup;
      this.downLoadParams = JSON.parse(JSON.stringify(params));
      breakdown(JSON.stringify(params)).then((res) => {
        this.tableData.map((item, index) => {
          let list = res.data.data.filter((v) => v.breakdown_object_id == item.id);
          item.hasChildren = true;
          this.$set(this.tableData, index, item);
          this.$refs.table.toggleRowExpansion(item, true);
          this.$refs.table.store.states.treeData[item.id].loaded = false;
          this.$refs.table.store.states.treeData[item.id].expanded = true;
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, item.id, list);
          this.$set(this.$refs.table.store.states.treeData, item.id, this.$refs.table.store.states.treeData[item.id]);
        });
      });
    },
    // 加载广告系列子集
    loadChildren(tree, treeNode, resolve) {
      let level = '';

      this.maps.set(tree.id, { tree, treeNode, resolve });
      if (this.$refs.table.store.states.lazyTreeNodeMap[tree.id]) {
        resolve(this.$refs.table.store.states.lazyTreeNodeMap[tree.id]);
        return false;
      }
      let obj;
      if (tree.level == 'campaign') {
        level = 'adset';
        obj = { field: 'campaign.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else if (tree.level == 'adset') {
        level = 'ad';
        obj = { field: 'adset.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else {
        setTimeout(() => {
          resolve([]);
        }, 100);
        return false;
      }
      // this.setDateRangeParam();
      // this.infiniteScrollDisabled = true;
      let params = JSON.parse(JSON.stringify(this.params));
      // this.$hideLoading();
      // this.loading = false;
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = ['name', 'status', 'id', 'campaign_ref', 'campaign_id', 'adset_id', 'purchase'].concat(filedList);
      params.account_id = tree.rowThirdNum + '_' + tree.rowAccountId;
      params.object_filtering = [obj];
      // params.filtering = [];
      let filtering = JSON.parse(JSON.stringify(this.params.filtering));
      params.filtering = filtering.filter((v) => v.field != 'status' && v.field != 'objective' && v.value !== '');
      params.level = level;
      params.after = '';
      this.getList(params, true, tree).then((res) => {
        setTimeout(() => {
          resolve(res);
        }, 200);
      });
    },
    // 获取点击name传过来的id
    openNextLevel(row, level) {
      this.$refs.table.clearSelection();
      this.$refs.table.toggleRowSelection(row, true);
      this.$emit('selection-change', this.level, [row]);
      this.$emit('select-tab', { name: level, row });
    },
    // 监听选择表格的选择项
    handleSelectionChange(v) {
      this.selectedRowScopes = v;
      this.list = v;
      this.$emit('selection-change', this.level, v);
    },
    // 清除表格选择项
    clearIds() {
      this.selectedRowScopes = [];
      this.$emit('selection-change', this.level, []);
      this.$refs.table.clearSelection();
    },
    // 获取表格细分项
    breakdownRules() {
      let params = {
        date_end: this.params.date_end,
        date_start: this.params.date_start,
      };
      breakdownRules(params).then((res) => {
        if (res.code == 0) {
          this.breakdownRulesArr = res.data;
          this.breakdownRulesArr.map((item) => {
            item.value = item.by;
            item.rules.map((item1) => {
              if (!item1.disable_when) {
                item1.disable_when = [];
              }
            });
          });
        }
      });
    },
    loadMoreClick() {
      if (this.loadText == '滚动/点击加载更多') {
        this.loadMore();
      }
    },
    // 下拉加载更多
    loadMore() {
      if (this.infiniteScrollDisabled) {
        return;
      }

      let params = JSON.parse(JSON.stringify(this.params));
      // if (data) {
      //   params = { ...params, ...data };
      // }

      let filedList = this.fields.map((v) => v.keyName);
      params.fields = ['name', 'status', 'id', 'campaign_ref', 'campaign_id', 'adset_id', 'purchase'].concat(filedList);
      params.account_id = this.currentAccount;

      params.object_filtering = params.object_filtering.filter(
        (v) => v.field != 'adset.id' && v.field != 'campaign.id',
      );
      // 处理筛选条件
      let filtering = JSON.parse(JSON.stringify(params.filtering)).filter(
        (v) => (v.value !== '' || v.value1.length) && v.field && v.operator,
      );

      filtering.map((item) => {
        if (item.field == 'status' || item.field == 'objective' || item.field == 'ad_label') {
          item.value = JSON.parse(JSON.stringify(item.value1));
        }
      });
      params.object_filtering = filtering
        .filter(
          (v) =>
            v.field == 'status' ||
            (v.field == 'objective' && v.value !== '' && v.value.length) ||
            (v.field == 'ad_label' && v.value !== '' && v.value.length),
        )
        .concat(this.objectFilteringBySection());
      params.filtering = filtering.filter(
        (v) => v.field != 'status' && v.field != 'objective' && v.field != 'ad_label' && v.value !== '',
      );

      let data = Object.assign({}, params, {
        after: this.currentAfter,
        level: this.level,
      });

      this.loadText = '努力加载中...';
      this.downLoadParams = JSON.parse(JSON.stringify(data));
      let { product_report, adProductReport, campaignPurchase, campaignCost } = { ...this.tableData[0] };
      this.getList(data).then(async (res) => {
        // 广告组层级，滚动加载加上经营分析
        if (data.level == 'adset') {
          res.map((v) => {
            v.product_report = product_report;
            v.adProductReport = adProductReport;
            v.campaignPurchase = campaignPurchase;
            v.campaignCost = campaignCost;
          });
        }
        this.tableData = this.tableData.concat(res);
        // 是否是前端排序
        if (this.manualSortFlag) {
          this.sortChange(this.manualSortFlag);
        }
        // if(!params.sort.length){
        //   this.tableData.sort(this.compare('statusIndex','ascending'))
        // }
        console.log('000000-----', this.accountIndex, this.accountList.length);
        if (res.length < params.page_length && this.accountIndex == this.accountList.length - 1) {
          // 当前账号加载完成，并且是最后一个账号
          this.accountListMap[this.currentAccount].loadedFinish = true;
          this.loadText = '没有更多数据了';
          this.infiniteScrollDisabled = true;
        } else if (res.length < params.page_length && this.accountIndex < this.accountList.length - 1) {
          // 当前账号加载完成，下一次加载下一个账号
          this.accountListMap[this.currentAccount].loadedFinish = true;
          this.accountIndex += 1;
          this.currentAfter = '';
          this.infiniteScrollDisabled = false;
          if (this.tableData.length < 50) {
            this.loadMore();
          }
        } else {
          // 当前账号没有加载完成
          this.currentAfter = this.after;
        }
        this.$refs.table.doLayout();
        // 广告系列-单独调用接口拿到经营分析数据
        // if (this.level == "campaign") {
        //   this.setCampaignAssessData();
        // }
      });
    },
    // 筛选条件存入缓存
    saveFilterStorage() {
      localStorage.setItem(`mutiAccoutFilter`, JSON.stringify(this.params.filtering));
    },
    // 获取标签列表
    async getLabelList() {
      await queryConfigLabel().then((res) => {
        // this.labelList = res.data;
        if (res.code != 0) return;
        this.$store.commit('adActiveTab/setCampaignLabel', res.data || []);
      });
    },
    // 获取列表
    getList(data, isChild, campaginInfo) {
      let params = data;
      params.fields = params.fields.concat(this.specialFields(params));
      this.cancelQuest();
      this.infiniteScrollDisabled = true;
      let account = params.account_id;
      params.account_id = account.split('_')[1] ? account.split('_')[1] : account;
      let thirdNum = '';
      if (!this.tableData.length) {
        this.$showLoading();
      }
      // this.params = {...params}
      this.loadText = '努力加载中';
      return report(JSON.stringify(params), this, thirdNum).then((res) => {
        this.$hideLoading();
        this.loadText = '滚动/点击加载更多';
        this.infiniteScrollDisabled = false;
        if (res.code != 0) {
          // this.$message({
          //     type:'error',
          //     message:res.comment
          // })
          return [];
        }
        if (params.level == this.level && res.data.paging.after) {
          this.after = res.data.paging.after;
          this.currentAfter = res.data.paging.after;
        }
        if (this.level == 'ad' && this.$refs.table) {
          this.$set(this.$refs.table.store.states, 'lazy', false);
        } else {
          this.$set(this.$refs.table.store.states, 'lazy', true);
        }
        res.data.data.map((v, index) => {
          // 给每一列加上本地订单数量,状态索引，方便本地排序
          v.localsOrderNum = v.local_order?.orderCount || 0;
          (v.statusIndex = v.effective_status ? getIndex(v.effective_status, v.level) : 100),
            (v.rowThirdNum = thirdNum);
          v.rowAccountId = params.account_id;
          if (v.level != 'ad') {
            v.hasChildren = true;
          } else {
            v.hasChildren = false;
          }
          // 经营分析
          if (v.level == 'adset') {
            v.adProductReport = this.getAdsetReport(v, isChild, campaginInfo);
          }
        });
        // 如果存在细分项，需要继续加载细分
        if (this.breakdownGroup.length && res.data.data.length) {
          this.breakdownAds(res.data.data);
        }
        // this.$refs.table.doLayout();
        this.summary = res.data.summary;
        return res.data.data ? res.data.data : [];
      });
    },
    operationLog(data) {
      this.operationData = data;
      this.showLog = true;
    },
  },
  created() {
    let filteringStr = localStorage.getItem(`mutiAccoutFilter`);
    if (filteringStr && JSON.parse(filteringStr).length) {
      this.params.filtering = JSON.parse(filteringStr);
      // this.cusFilterList = JSON.parse(filteringStr);
      this.filterShow = true;
    }
    this.debouncedReloadTable = _.debounce(this.reloadTable, 500);
  },
};
</script>

<style lang="scss">
.rule-tip {
  .rule-score-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.color9 {
  color: #999;
  font-size: 12px;
}
.ACTIVE {
  background: #67c23a;
}

.PAUSED,
.IN_PROCESS {
  background: #e6a23c;
}
.nameDELETED,
.nameARCHIVED {
  @extend .color9;
}

.DELETED {
  background: #f56c6c;
}

.DISAPPROVED {
  background: #ff0000;
}
.PREAPPROVED,
.ARCHIVED {
  background: #409eff;
}

.DELETED,
.WITH_ISSUES,
.PENDING_BILLING_INFO,
.ADSET_PAUSED {
  background: #f56c6c;
}

.PENDING_REVIEW,
.CAMPAIGN_PAUSED {
  background: #909399;
}

.adManagement-container .el-tabs__content {
  padding: 0;
}
.el-icon-info {
  color: #999;
  margin-left: 10px;
}
.el-icon-warning {
  color: #e6a23c;
}
.el-icon-error {
  color: #f56c6c;
}
.adManagement-container {
  .el-table .caret-wrapper {
    width: 0;
  }
}

.toolsCheckTips {
  .el-checkbox,
  .el-radio {
    line-height: 36px;
  }
}

.dropselect {
  .el-select-dropdown__item {
    padding: 0;

    span {
      display: block;
      padding: 0 20px;
    }
  }
}

.el-table__body-wrapper {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}

.statusWarp {
  display: flex;
  align-items: center;

  .pointer {
    flex: 8px 0 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.breakdownList {
  li {
    line-height: 26px;
  }
}

.cusFilter {
  padding: 0 10px 0 20px;

  .cusFilterList {
    .cusFilterItem {
      margin-bottom: 10px;
    }
  }
}

.el-dropdown-menu__item {
  &.active {
    background: #ecf5ff;
    color: #66b1ff;
  }

  .columnName {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      p {
        display: block;
      }
    }

    div {
      p {
        flex: 60px 0 0;
        display: none;

        i {
          margin: 0 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

.tagInput {
  width: 30px;

  input {
    width: auto;
    border: none;
    outline: none;
    background: transparent;
    padding: 0 5px;
    width: 30px;
    border-bottom: 1px dashed #fff;
    color: #fff;
    height: 18px;
    line-height: 18px;
  }
}

.el-popover {
  min-width: 100px;
}
.drawerCon {
  height: calc(100vh - 150px);
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}
.tableWrap {
  .el-table .cell {
    line-height: unset;
  }
}
// 状态
.header-status {
  .cell {
    display: flex !important;
    align-items: center;
  }
  .caret-wrapper {
    top: 2px;
  }
}
.my-table.el-table .el-table__body-wrapper {
  height: calc(100vh - 280px);
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.toolsBar {
  margin: 10px 0;
  background: rgb(232, 241, 255);
  line-height: 32px;
  height: 42.38px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #409eff;
  font-size: 12px;
  .close {
    cursor: pointer;
  }
  .toolsCon {
    display: flex;
    align-items: center;
    i {
      font-size: 16px;
    }
    p {
      background: #409eff;
      line-height: 24px;
      border-radius: 14px;
      padding: 0 10px;
      font-size: 12px;
      color: #fff;
      margin-right: 10px;
    }
    //   .toolsOne{
    //       margin: 0 5px;
    //       width: 45px;
    //       text-align: center;
    //       position: relative;
    //       display: inline-block;
    //       vertical-align: middle;
    //       .el-dropdown{
    //           height: 8px;
    //       }
    //       span{
    //           display: inline-block;
    //           position: absolute;
    //           top: 50%;
    //           left: 50%;
    //           text-align: center;
    //           width: 100%;

    //           transform: translate3d(-50%,-50%,0);
    //           i{
    //               line-height: 32px;
    //           }
    //       }
    //       .el-button{
    //           display: none;
    //       }
    //       &:hover{
    //         span{
    //             display: none;
    //         }
    //         .el-button{
    //             display: inline-block;
    //             position: absolute;
    //             top: 50%;
    //             left: 50%;
    //             margin-left: 0;
    //             // width: 100%;
    //             // padding: 5px 15px;
    //             transform: translate3d(-50%,-50%,0);
    //         }
    //       }
    //   }
  }
}
.tableWrap {
  padding: 16px;
  padding-top: 0;

  .loadMore {
    text-align: center;
    line-height: 28px;
    color: #999;
  }
}
.el-table .caret-wrapper {
  width: 0;
}
.adManagement-container {
  width: 100%;
  height: calc(100vh - 82px);
  background: #fff;

  .filterWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf5ff;

    .el-image {
      flex: 14px 0 0;
      margin-left: 5px;
    }

    .paramsFilters {
      flex: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .el-tag {
        margin: 3px;
        display: flex;
        align-items: center;

        .checkbox-group {
          padding: 0 10px;

          .el-checkbox {
            display: block;
          }
        }
      }
    }

    .rightIcon {
      flex: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 15px;
        font-weight: bold;
        margin: 0 5px;
        cursor: pointer;
        color: #666;
      }
    }
  }

  .el-tabs__item {
    .el-button {
      padding: 4px 10px;
      margin-left: 5px;
    }
  }

  .table-top-bar {
    // position: absolute;
    // top: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    // height: 32px;
    padding: 10px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightTools {
      flex: 260px 0 0;
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;

      .tools {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toolsItem {
          margin: 0 4px;
        }
      }

      .searchInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-button {
          color: #666;
          font-size: 14px;
          margin: 0 5px;
        }
      }
    }

    .midFilter {
      flex: 1;
      padding: 0 10px;
    }

    .table-top-bar-right {
      flex: 1 1 0%;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s linear 0s;
      // padding-right: 140px;
      .filter-btn {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        color: #8c8c8c;
        margin-left: 12px;
        padding: 0 4px;
        border-radius: 2px;
        transition: all 0.3s linear 0s;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }

      .filter-btn:hover {
        background: #eaeaed;
      }

      .filter-operation {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        width: auto;
        padding-left: 10px;
        transition: all 0.3s linear 0s;

        .filter-operation-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          transition: all 0.3s linear 0s;
          cursor: pointer;

          i {
            color: gray;
          }
        }

        .filter-operation-item:hover {
          background: #eaeaed;
        }
      }
    }
  }
}
// 批量操作的图标去掉直接展示文字
.operate-btn {
  margin-right: 5px;
}
</style>
